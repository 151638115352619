<template>
	<basePopup :okFunc="updateDeal" okText="딜 연동" size="xl" title="딜 연동">
		<collapse :title="'현재 연결된 상품 코드 : ' + productNum">
			<div v-if="isPossibleNormal">
				<tb :inf="pInf" :res="pRes" limit="9999" nav="none" title="일반상품" />
			</div>
			<div v-if="isPossibleRental">
				<tb :inf="rtpInf" :res="rtpRes" limit="9999" nav="none" title="렌탈상품" />
			</div>
		</collapse>
		<div class="flex">
			<p>상품코드 필터</p>

			<vSelect
				class="w-60 -mt-1.5 ml-4"
				v-model="selectProductNum"
				:clearable="false"
				:options="productNumOpts"
			/>
		</div>
		<tb :inf="inf" :pr="ths" :res="filterRes" checkBoxKey="dealNum" limit="9999" nav="none" />
	</basePopup>
</template>

<script>
import basePopup from 'comp/local/basePopup'
import collapse from 'comp/collapse'

const filterAll = {
	label: '전체',
	value: '',
}
export default {
	props: {
		cName: { default: 'productAddPopup' },
		baseInf: { default: '' },
		isPossibleNormal: { default: true },
		isPossibleRental: { default: false },
	},
	components: { basePopup, collapse },
	data() {
		return {
			isInit: false,
			isShow: false,
			productGroupIdx: '',
			productNum: '',
			origin: {},

			productNumOpts: [],
			selectProductNum: filterAll,

			inf: [
				//변경 시 slice로 가져오는 것들 순서 변경해줘야 할 수도 있다
				{ model: 'checkAll', size: 1 },
				{ title: '딜 번호', model: 'urlText', url: 'dealLink', text: 'dealNum', size: 2 },
				{ title: '딜 타입', key: 'dealType', size: 2 },
				{ title: '상품코드', key: 'productNum', size: 4 },
				{ title: '핫트 아이디', key: 'hottId', size: 3 },
				{ title: '딜 이름', key: 'dealName', size: 8, notCenter: 1 },
				{ title: '상품 구분', key: 'productType', size: 4 },
				{ title: '소비자가', key: 'customerPrice', size: 3 },
				{ title: '판매가', key: 'salePrice', size: 3 },
				{ title: '렌탈 개월', key: 'rentalMonth', size: 3 },
				{ title: '렌탈 판매가', key: 'rentalPriceInterest', size: 3 },
				{ title: '렌탈 월 비용', key: 'rentalMonthPriceInterest', size: 3 },
				{ title: '배송비', key: 'deliveryPrice', size: 3 },
				{ title: '무료배송 조건', key: 'deliveryFreePrice', size: 3 },
				{ title: '제주도', key: 'jejuDeliveryPrice', size: 3 },
				{ title: '도서산간', key: 'etcDeliveryPrice', size: 3 },
				{ title: '묶음배송비', key: 'bundleDeliveryCount', size: 3 },
				{ title: '딜 시작일', key: 'startDate', size: 4 },
				{ title: '딜 종료일', key: 'endDate', size: 4 },
			],
			res: [],

			pInf: [],
			pRes: [],
			rtpInf: [],
			rtpRes: [],
		}
	},
	methods: {
		open(origin) {
			if (!this.isInit) {
				this.isInit = true

				this.origin = origin
				this.productGroupIdx = origin.productGroup.productGroupIdx
				this.productNum = origin.productGroup.productCode
				;['', 'rt'].map(key => {
					const possible = key ? this.isPossibleRental : this.isPossibleNormal
					if (possible) {
						const numType = []
						this[key + 'pInf'] = [
							{
								key: 'productNum',
								title: '상품코드',
								size: 3,
							},
							...this.baseInf.slice(1, 13).map((v, i) => {
								//상품명~순서2
								const res = {
									key: v.key,
									title: v.title,
									size: v.size,
								}
								if (i < 3)
									//~하위옵션
									res.notCenter = 1
								else if (i < 10)
									//~핫트 수수료
									numType.push(res.key)
								return res
							}),
						]
						if (key) {
							//렌탈일 경우
							this.rtpInf.splice(5, 1)
							//-소비자가
							this.rtpInf.splice(6, 0, {
								key: 'rentalMonth',
								title: '렌탈개월',
								size: 2,
							})
							this.rtpInf.splice(7, 0, {
								key: 'rentalMonthPriceInterest',
								title: '렌탈월비용',
								size: 3,
							})
							//+렌탈개월 + 렌탈월비용
						}

						const res = [],
							pkey = key ? 'rentalProducts' : 'products'
						this.origin[pkey].map(p => {
							const arr = copyVar(p),
								products = arr.productOptions
							delete arr.productOptions
							//products.map(v => this.pRes.push({ ...arr, ...v }))
							products.map(v => {
								numType.map(k => (v[k] = numFormat(v[k])))
								res.push({ ...arr, ...v })
							})
						})
						//this.$set(this, 'pRes', res)
						this[key + 'pRes'] = getTempPaging(res, res.length)
					}
				})

				if (this.isPossibleNormal) {
					const numType = []
					this.pInf = [
						{
							key: 'productNum',
							title: '상품코드',
							size: 3,
						},
						...this.baseInf.slice(1, 13).map((v, i) => {
							//상품명~순서2
							const res = {
								key: v.key,
								title: v.title,
								size: v.size,
							}
							if (i < 3)
								//~하위옵션
								res.notCenter = 1
							else if (i < 10)
								//~핫트 수수료
								numType.push(res.key)
							return res
						}),
					]

					const res = []
					this.origin.products.map(p => {
						const arr = copyVar(p),
							products = arr.productOptions
						delete arr.productOptions
						//products.map(v => this.pRes.push({ ...arr, ...v }))
						products.map(v => {
							numType.map(k => (v[k] = numFormat(v[k])))
							res.push({ ...arr, ...v })
						})
					})
					//this.$set(this, 'pRes', res)
					this.pRes = getTempPaging(res, res.length)
				}
			}
			postApi('api/backoffice/dealSelect/productUpdateDealList', { productGroupIdx: this.productGroupIdx }).then(
				res => {
					const numType = this.inf.slice(7, 16),
						//소비자 가격 ~ 도서산간
						dateType = ['startDate', 'endDate'],
						productNumOpts = [{ ...filterAll }]
					this.selectProductNum = filterAll
					res = res.map(v => {
						v.dealLink = `${env.url.dealHost}/${v.dealNum}`
						numType.map(k => (v[k.key] = numFormat(v[k.key])))
						dateType.map(k => (v[k] = v[k].replace('T', ' ')))
						v.bundleDeliveryCount = v.bundleDeliveryCount
							? v.bundleDeliveryCount + '개마다 부과'
							: '해당없음'

						if (productNumOpts.indexOf(v.productNum) == -1) productNumOpts.push(v.productNum)
						return v
					})
					this.productNumOpts = productNumOpts
					this.res = res
				}
			)
			this.isShow = true
		},
		updateDeal() {
			const dealNums = this.tb.getChecked('dealNum')
			if (dealNums.length) {
				putApi('api/backoffice/dealHandle/updateProductConnectDeals', {
					productGroupIdx: this.productGroupIdx,
					dealNums,
				}).then(() => {
					alert.s('딜 연동이 완료되었습니다.')
					this.isShow = false
				})
			} else alert.w('선택된 딜이 없습니다')
		},
	},
	computed: {
		filterRes() {
			const productNum = getDropdownValue(this.selectProductNum)
			let res
			if (!productNum) res = this.res
			else res = this.res.filter(v => v.productNum == productNum)

			return getTempPaging(res, res.length)
		},
	},
}
</script>
