<!--이따 quill은 이걸로 통합할 것-->
<template>
	<div class="bg-white h-120 pb-20 quill">
		<div id="toolbar" />
		<div id="editor-container" />
	</div>
</template>

<script>
import { uploadFile } from 'libs/axios'

import Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'

//import nqc from 'node-quill-converter'
//console.log(nqc)

const Link = Quill.import('formats/link')

class MyLink extends Link {
	static create(value) {
		const node = super.create(value)
		value = this.sanitize(value)
		node.setAttribute('href', value)
		node.setAttribute('target', '_self')
		return node
	}
}

Quill.register(MyLink)
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)

export default {
	props: {
		fileType: { default: 'none' },
		etcData: { default: {} },
		cName: { default: 'quill' },
		maxImgSize: { default: 3 },
	},
	data() {
		return {
			quill: {},
			editor: {},
		}
	},
	created() {
		const ths = this
		setTimeout(() => {
			this.quill = new Quill('#editor-container', {
				theme: 'snow',
				modules: {
					imageDropAndPaste: {
						handler: (srcUrl, mime, imgData) => this.imgHandler(imgData.toFile(imgData.name)),
					},
					toolbar: {
						container: ['image', 'video'],
						handlers: {
							image() {
								const input = document.createElement('input')
								input.setAttribute('type', 'file')
								input.setAttribute('accept', 'image/*')
								input.onchange = () => ths.imgHandler(input.files[0])
								input.click()
							},
						},
					},
				},
			})
			this.editor = this.$el.getElementsByClassName('ql-editor')
			if (this.editor.length) this.editor = this.editor[0]
		}, 0)
	},
	methods: {
		imgHandler(file) {
			if (file.size >= 1024 * 1024 * this.maxImgSize)
				alert.w(this.maxImgSize + 'MB 이하 이미지만 업로드 가능합니다.')
			else {
				uploadFile(file, this.fileType, undefined, undefined, this.etcData).then(url =>
					//?? 이건 왜 s3 폴더명이 붙어서 바로 사용 못하도록 내려오냐..
					this.quill.insertEmbed(this.quill.getSelection().index, 'image', `${s3Url}${/\/.*/.exec(url)[0]}`)
				)
			}
		},
		setHtml(html) {
			this.quill.clipboard.dangerouslyPasteHTML(html)
		},
		getHtml() {
			return this.editor.innerHTML
		},
		setCont(origin) {
			//this.quill.setContents(origin)
			//quill에서 제공하는 setContents를 그냥 사용하면 실제 델타 배열의 순서에 무관하게 이미지가 먼저 나오고 뒤에 동영상이 나오는 문제가 있다..
			this.quill.setContents([{ insert: '' }])
			if (!Array.isArray(origin)) return

			copyVar(origin).map(v => {
				let isYoutube = false
				if (v.indexOf('youtube=') != -1) {
					v = 'https://www.youtube.com/embed/' + v.split('youtube=')[1]
					isYoutube = true
				} else if (v.indexOf('youtube.com/') != -1) isYoutube = true

				const imgType = isYoutube ? 'video' : 'image'
				this.quill.insertEmbed(99999999, imgType, v)
			})
		},
		getCont() {
			return this.quill.getContents()
		},
	},
}
</script>

<style lang="scss">
.quill {
	#editor-container {
		.ql-editing {
			position: absolute;
			top: 0rem !important;
			left: 5rem !important;
			margin-top: 0 !important;
		}

		img {
			display: block;
			width: 800px;
		}

		.ql-video {
			width: 800px;
			height: 450px;
		}
	}
}
</style>
