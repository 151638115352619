<template>
	<h6>
		{{ title }}
		<b-button class="-mt-1.5" v-b-tooltip.hover.html :title="exp" size="sm" variant="no">
			<b-icon font-scale="1.2" icon="info-circle" />
		</b-button>
	</h6>
</template>

<script>
export default {
	props: {
		title: { default: '' },
		exp: { default: '' },
	},
}
</script>
