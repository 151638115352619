<template>
	<div>
		<p>기본정보</p>
		<b-card class="mb-4">
			<h6 v-if="productGroupIdx">idx: {{ productGroupIdx }}</h6>

			<h6>업체명</h6>
			<slot name="partnerSlot" />

			<div v-for="v in baseInf[0]" class="mb-4">
				<h6>{{ v.title }}</h6>
				<b-form-input class="w-1/2" v-model.trim="p.input[v.key]" :name="v.key" :placeholder="v.hold" />
			</div>
			<div class="mb-4">
				<h6>상품 한 줄 설명</h6>
				<b-form-input
					class="w-1/2 inline-block"
					v-model="p.input.onelineCont"
					name="onelineCont"
					placeholder="상품에 대한 짧은 설명을 작성해주세요."
					@keyup="
						() => {
							p.input.onelineCont = p.input.onelineCont.substr(0, 50)
						}
					"
				/>
				<p class="inline-block lh-8 ml-2">( {{ p.input.onelineCont.length }}/50 )</p>
			</div>

			<slot name="baseInfoFooterSlot" />
		</b-card>

		<p>가격 및 카테고리</p>
		<b-card class="mb-4">
			<expTitle
				exp="리스트와 상세화면에 노출될 상품의 대표 가격입니다.<br>주력 판매옵션의 가격을 기입해주세요"
				title="대표 상품 가격"
			/>
			<div v-for="v in baseInf[1]" v-if="!v.show || v.show()" class="inline-block mb-4">
				<h6>{{ v.title }}</h6>
				<b-form-input
					class="w-40 text-right inline-block"
					v-model.trim="p.input[v.key]"
					:name="v.key"
					:placeholder="v.title"
					@keyup="
						() => {
							p.input[v.key] = numFormat(p.input[v.key])
							if (v.func) v.func()
						}
					"
				/>
				<p class="mt-1.5 inline-block mr-8">원</p>
			</div>
			<div v-if="p.isPossibleRental" class="inline-block mb-4">
				<div class="inline-block">
					<h6>렌탈 개월</h6>
					<vSelect
						class="w-40"
						v-model="p.input.rentalMonth"
						@input="changeGroupRentalPrice"
						:clearable="false"
						:options="rentalRatioOpts"
					/>
				</div>
				<div class="inline-block ml-4">
					<p
						v-for="v in [
							{ title: '렌탈총비용', key: 'rentalPriceInterest' },
							{ title: '렌탈월비용', key: 'rentalMonthPrice' },
						]"
						class="mt-2"
					>
						{{ v.title }}: {{ numFormat(ths[v.key]) }}원
					</p>
				</div>
			</div>

			<slot name="feeNClassSlot" />

			<expTitle
				class="mt-3"
				exp="핫티스트와 구매 고객이 기준으로 삼는 카테고리 입니다.<br>- 최대한 유사한 쪽으로 설정해주세요"
				title="노출 카테고리"
			/>

			<div class="flex">
				<b-form-checkbox
					v-for="v in [
						{ title: '유아', val: 1 },
						{ title: '생활', val: 2 },
						{ title: '반려', val: 3 },
						{ title: '뷰티', val: 4 },
						{ title: '식품', val: 5 },
					]"
					class="mr-4"
					v-model="p.input.category"
					:value="v.val"
					>{{ v.title }}
				</b-form-checkbox>
			</div>

			<expTitle
				class="mt-3"
				exp="상품의 추천, 핫티스트 성향에 따른 매칭 등의 외부에 노출되지 않는 기준으로 활용되는 카테고리입니다.<br>- 최대한 유사한 쪽으로 설정해주세요"
				title="상세 카테고리"
			/>
			<div class="flex mb-4">
				<vSelect
					v-for="i in [1, 2, 3]"
					class="w-1/4 mr-4 top-1.5"
					v-model="p.input['category' + i]"
					@input="initDetailCategory(i)"
					:clearable="false"
					:options="ths['categoryComp' + i]"
				/>
			</div>

			<h6>해시태그</h6>
			<p>※해시 태그는 ,로 구분하여 입력합니다.<br />상세 카테고리 항목은 자동으로 해시태그로 입력됩니다</p>
			<div class="flex">
				<b-form-input
					v-model="p.input.hashTag"
					name="hashTag"
					placeholder="해시태그를 입력해주세요"
					@keyup="
						() => {
							p.input.hashTag = p.input.hashTag.substr(0, 200)
						}
					"
				/>
				<p class="w-30 lh-8 ml-2">( {{ p.input.hashTag.length }}/200 )</p>
			</div>
		</b-card>

		<p>배송비 정책</p>
		<b-card class="mb-4">
			<expTitle
				class="mt-3"
				exp="※ 조건부 무료 : 특정금액 이상 주문 시 배송비를 무료로 합니다."
				title="배송비 설정"
			/>
			<div class="flex">
				<b-form-radio
					v-for="v in [
						{ title: '무료', val: 1 },
						{ title: '유료', val: 2 },
						{ title: '조건부 무료', val: 3 },
					]"
					class="mr-4"
					v-model="p.input.deliveryType"
					name="deliveryType"
					:disabled="!p.isPossibleSetDeliveryPrice"
					:value="v.val"
				>
					{{ v.title }}
				</b-form-radio>
			</div>
			<div v-show="p.isPossibleSetDeliveryPrice">
				<div class="mt-3">
					<div
						v-for="v in [
							{
								title: '기본 배송비',
								key: 'deliveryPrice',
								exp: '',
								show: () => p.input.deliveryType != 1,
							},
							{
								title: '무료 배송 조건',
								key: 'deliveryFreePrice',
								exp: ' 이상 구매시 배송비 무료',
								show: () => p.input.deliveryType == 3,
							},
						]"
						:class="v.show() ? 'inline-block' : 'hide'"
					>
						<b-form-input
							class="w-40 text-right inline-block"
							v-model.trim="p.input[v.key]"
							name="deliveryPrice"
							:placeholder="v.title"
							@keyup="p.input[v.key] = numFormat(p.input[v.key])"
						/>
						&nbsp;&nbsp;
						<p class="mt-1 inline-block mr-8">원{{ v.exp }}</p>
					</div>
				</div>

				<div
					v-for="v in [
						{ title: '제주도', key: 'jeju' },
						{ title: '도서산간', key: 'etc' },
					]"
					class="mt-3"
				>
					<h6>{{ v.title }} 배송여부</h6>
					<div class="flex">
						<b-form-radio
							v-for="r in [
								{ title: '해당없음', val: 'noCharge' },
								{ title: '배송불가', val: 'none' },
								{ title: '추가 배송비', val: 'possible' },
							]"
							class="mr-4"
							v-model="p.input[v.key + 'DeliveryType']"
							:name="v.key + 'DeliveryType'"
							:value="r.val"
						>
							{{ r.title }}
						</b-form-radio>
						<b-form-input
							class="w-40 text-right inline-block -mt-1.5"
							v-model.trim="p.input[v.key + 'DeliveryPrice']"
							name="deliveryPrice"
							:placeholder="v.title + ' 추가 배송비'"
							@focus="p.input[v.key + 'DeliveryType'] = 'possible'"
							@keyup="p.input[v.key + 'DeliveryPrice'] = numFormat(p.input[v.key + 'DeliveryPrice'])"
						/>&nbsp;&nbsp;
						<p class="mt-0.5">원</p>
					</div>
				</div>

				<expTitle
					class="mt-3"
					exp="수량대비 추가배송비와 합배송 설정<br>수량마다 부과설정 클릭 시 개수 설정 가능(숫자만 입력 가능)"
					title="기타 배송비 설정"
				/>
				<b-form-checkbox v-model="p.input.isCombinedDelivery">
					동일브랜드 한번만 부과(합배송) 설정
				</b-form-checkbox>

				<b-form-checkbox v-model="p.input.isBundleDelivery"> 수량마다 부과 설정 </b-form-checkbox>
				<div class="flex my-2">
					<b-form-input
						class="w-40 text-right -mt-1.5"
						v-model.trim="p.input.bundleDeliveryCount"
						name="bundleDeliveryCount"
						placeholder="수량별 추가 배송비"
						@click="p.input.isBundleDelivery = true"
						@keyup="p.input.bundleDeliveryCount = numFormat(p.input.bundleDeliveryCount)"
					/>&nbsp;&nbsp;
					<p class="mt-0.5">개 주문 시마다 배송료 부과</p>
				</div>
			</div>
		</b-card>

		<p>옵션 설정</p>
		<b-card class="mb-4">
			<slot name="productCodeSlot" />

			<div class="inline-block w-1/3 pr-4 mb-4">
				<h6 class="mb-3.5">상품 옵션 일괄 생성</h6>
				<div
					v-for="v in [
						...inf.slice(1, 4),
						//상품명, 상위옵션, 하위옵션
					]"
					class="flex mt-2"
				>
					<h6 class="w-30 lh-9" v-text="v.title" />
					<b-form-input
						v-model.trim="p.input[v.key]"
						:name="'createProduct' + v.key"
						:placeholder="v.title + ' 일괄 생성(쉼표로 구분)'"
					/>
				</div>
				<b-button class="mt-3 w-1/1" @click="make" v-text="'옵션 일괄 생성'" />
			</div>
			<div class="inline-block w-1/3">
				<h6 class="block pl-4">내용 일괄 입력</h6>
				<div
					v-for="v in inputAllInf"
					v-if="!p.input.allTarget || v.key != 'customerPrice'"
					class="inline-block w-1/2 px-4"
				>
					<h6 class="block">{{ v.title }}</h6>
					<b-form-input
						class="mb-3"
						v-model.trim="p.input.all[v.key]"
						:name="'all' + v.key"
						:placeholder="v.title + ' 일괄 입력'"
						@input="numFilter(v.key)"
					/>
				</div>
				<div v-if="p.input.allTarget" class="inline-block w-1/2 px-4">
					<h6 class="block">렌탈개월</h6>
					<vSelect v-model="p.input.all.rentalMonth" :clearable="false" :options="rentalRatioOpts" />
				</div>
				<div class="px-4 pt-3.5">
					<b-button class="w-1/1" @click="inputAll" v-text="'일괄 입력'" />
				</div>
			</div>

			<slot name="inputAllSlot" />
		</b-card>

		<b-card class="mb-4">
			<div v-if="enrollDate" class="flex-between">
				<h6>등록일: {{ enrollDate.replace('T', ' ') }}</h6>
				<b-button class="-mt-2.5 mb-2.5" @click="downloadProductOpts" variant="gray" v-text="'엑셀 다운로드'" />
			</div>
			<productTb v-show="p.isPossibleNormal" @changeCode="changeCode" :inf="inf" :ores="res" :pr="ths" />
			<productTb
				v-show="p.isPossibleRental"
				@changeCode="changeCode"
				:inf="rtinf"
				:isRental="true"
				:ores="rtres"
				:pr="ths"
				:rentalRatioOpts="rentalRatioOpts"
				cName="rtproductTb"
			/>

			<br />
			<expTitle
				exp="매달 제공할 수 있는 무상협찬품의 개수를 설정합니다.<br>※ 3개 설정 시 > 매달 3건의 무상 협찬공구 진행하며, <br>그만큼의 협찬품을 매달 인플루언서에게 무상 제공 한다는 뜻"
				title="매달 제공 무상협찬품 개수"
			/>
			<div class="flex mt-2 mb-4">
				월 최대
				<b-form-input
					class="w-20 -mt-1.5 mx-1 text-right"
					v-model.trim="p.input.monthlyMaxCount"
					name="monthlyMaxCount"
				/>
				개
			</div>

			<slot name="historySlot" />
		</b-card>

		<p>이미지</p>
		<b-card>
			<div
				v-for="v in [
					{ key: 'mainImage', text: '대표 이미지', func: () => 1 },
					{ key: 'priceImage', text: '가격 이미지', func: () => !isPartner },
				]"
				v-if="v.func()"
				class="mb-4"
			>
				<h6>상품 {{ v.text }} 등록</h6>
				<div v-show="p.input[v.key]" class="mainImgWrap">
					<img class="mainImg" :src="p.input[v.key]" alt="img" @click="$refs[v.key][0].$refs.input.click()" />
					<b-button
						class="absolute top-0 right-0"
						@click="
							() => {
								$refs[v.key][0].$refs.input.value = ''
								p.input[v.key] = ''
							}
						"
						variant="no"
					>
						<b-icon font-scale="1" icon="x" />
					</b-button>
				</div>

				<b-form-file
					v-show="false"
					:ref="v.key"
					placeholder="상품 이미지를 등록해주세요."
					@change="setImg(v.key)"
					accept="image/*"
					browse-text="파일 찾기"
				/>

				<div
					v-show="!p.input[v.key]"
					class="w-30 h-30 imgBtn cursor-pointer"
					@click="$refs[v.key][0].$refs.input.click()"
				>
					<b-icon class="w-1/2 h-1/2 text-center verticalCenter" icon="plus" />
				</div>
			</div>

			<h6>상세 내용 등록</h6>
			<quill :etcData="imgEtcData" :pr="ths" fileType="detailImage" maxImgSize="10" />

			<slot name="productFooter" />
			<!--상품정보제공 고시 작성여부 확인, 기타 소개 코멘트 등록 + 버튼?-->
		</b-card>
	</div>
</template>

<script>
import productTb from 'pages/productTb'
import expTitle from 'comp/local/productAddExpTitle'
import { uploadFile } from 'libs/axios'
import quill from 'comp/local/quill'
import js from 'comp/local/productJs'

export default {
	props: {
		cName: { default: 'childTag' },
		isPartner: { default: true },
		productGroupIdx: { default: '' },
		origin: { default: '' },
		inf: { default: '' },
		rentalRatioOpts: { default: '' },
	},

	components: {
		productTb,
		expTitle,
		quill,
	},
	data() {
		const ths = this
		return {
			ths,
			res: [],

			rtinf: [
				...this.inf.slice(0, 5),
				//추가/삭제~재고수량
				...this.inf.slice(6, 7),
				//판매가
				{},
				//렌탈개월
				{ title: '렌탈월비용', key: 'rentalMonthPrice', size: 4 },

				...this.inf.slice(7, 99),
				//내돈내산가~
			],
			rtres: {},

			categoryOpts1: [],
			categoryOpts2: [],
			categoryOpts3: [],

			baseInf: [
				[
					{
						title: '브랜드명',
						hold: '브랜드명 입력. 동일한 브랜드명을 입력하면 같은 브랜드로 인식됩니다.',
						key: 'brandName',
					},
					{
						title: '상품명',
						hold: '브랜드명을 제외한 상품명을 입력해주세요.',
						key: 'displayProductName',
					},
				],
				[
					{
						title: '소비자가',
						key: 'customerPrice',
					},
					{
						title: '판매가',
						key: 'salePrice',
						show: () => this.p.isPossibleNormal,
					},
					{
						title: '내돈내산가',
						key: 'selfDealPrice',
						show: () => !this.isPartner,
					},
					{
						title: '렌탈판매가',
						key: 'rentalPrice',
						show: () => this.p.isPossibleRental,
						func: this.changeGroupRentalPrice,
					},
				],
			],

			baseItem: {
				productKey: '',
				isSponsored: true,
				stock: '',
				customerPrice: '',
				salePrice: '',
				selfDealPrice: '',
				sponsoredSupplyPrice: '',
				purchasePrice: '',
				hottistCharge: '',
				hottCharge: '',
				saveStatus: true,
				isChargeAdd: true,
				showSponsoredSupply: true,
				showSelfDeal: true,

				rentalMonth: { fee: '', value: '' },
				rentalMonthPrice: 0,
				rentalPriceInterest: 0,
			},
		}
	},
	created() {},
	methods: {
		initDetailCategory(start = 0) {
			for (start = start + 1; start < 4; start++) this.p.input['category' + start] = { label: '선택', value: 0 }
		},

		getCategoryInfo(afterFunc) {
			return postApi('/api/backoffice/productSelect/productGroupCategoryList').then(v => {
				this.initDetailCategory()
				;[1, 2, 3].map(k => {
					this['categoryOpts' + k] = v['depth' + k].map(vv => {
						return {
							label: vv.categoryName,
							parent: vv.parentSeq,
							value: vv.productGroupCategorySeq,
						}
					})
				})
				if (typeof afterFunc == 'function') afterFunc()
			})
		},

		make() {
			//옵션 일괄 생성
			let { productName, productOption1, productOption2 } = this.p.input
			if (productName || productOption1 || productOption2) {
				const res = [],
					opt1 = productOption1.split(','),
					opt2 = productOption2.split(','),
					isRental = this.p.input.allTarget
				productName.split(',').map((n, i) => {
					const productName = n.trim(),
						comm = {
							productSequence: i + 1,
							showSelfDeal: true,
							showSponsoredSupply: true,
							productName,
						}
					opt1.map((o1, j) => {
						opt2.map((o2, k) => {
							if (!j) comm.len = opt1.length * opt2.length
							res.push({
								comm,
								...this.baseItem,
								productName,
								productOption1: o1.trim(),
								productOption2: o2.trim(),
								//productNum: i,
								j: j + k,
								isRental,
							})
						})
					})
				})
				this[isRental ? 'rtproductTb' : 'productTb'].setOpt(res)
				//this.res = res
				//여기까지는 데이터 나열. DB에서 불러왔을 때랑 같은 형태
			} else alert.w('옵션 정보를 입력해주세요')
		},
		setError(v, key, isSet = true) {
			this.$set(v, key + 'Class', isSet ? 'err' : '')
		},
		changeCode() {
			this.p.isEssentialChangeCode = true
		},
		calcFee(list) {
			if (!Array.isArray(list)) list = [list]

			const supplyPrice = num(this.p.supplyPrice),
				hottistCharge = num(this.p.hottistCharge)
			list.map(v => {
				const salePrice = num(v.salePrice)
				let hottiCharge
				if (!v.isEditCharge) hottiCharge = Math.floor((salePrice * hottistCharge) / 100)
				else hottiCharge = num(v.hottistCharge)

				v.purchasePrice = comma(v.purchasePrice)

				if (!v.isEditSupply) v.sponsoredSupplyPrice = comma(supplyPrice)
				v.hottistCharge = comma(hottiCharge)
				v.hottCharge = comma(salePrice - num(v.sponsoredSupplyPrice) - hottiCharge)

				if (v.isRental) {
					const { rentalMonthPrice, rentalPriceInterest } = this.getRentalPrice(v.rentalMonth, salePrice)

					v.rentalMonthPrice = numFormat(rentalMonthPrice)
					v.rentalMonthPriceInterest = rentalMonthPrice
					v.rentalPriceInterest = numFormat(rentalPriceInterest)
				}
			})
		},

		numFilter(key) {
			const inp = this.p.input.all
			if (inp[key]) inp[key] = numFormat(inp[key])
		},
		inputAll() {
			const isRental = this.p.input.allTarget,
				key = isRental ? 'rt' : ''

			if (this[key + 'productTb'].res.length) {
				const editList = this.inputAllInf.filter(v => {
					if (this.p.input.all[v.key] && (!isRental || v.key != 'customerPrice')) return true
					return false
				})
				if (editList.length || isRental) {
					editList.map(k => {
						switch (k.key) {
							case 'customerPrice':
							case 'salePrice':
								this.p.isEssentialChangeCode = true
						}
						this[key + 'productTb'].res.map(v => {
							v[k.key] = this.p.input.all[k.key]
							if (k.key == 'salePrice') this.calcFee(v)
						})
					})
					if (isRental && typeof this.p.input.all.rentalMonth == 'object') {
						//렌탈인 경우 소비자가는 입력 안 하고 렌탈개월을 처리한다
						this.p.isEssentialChangeCode = true
						this.rtproductTb.res.map(v => {
							v.rentalMonth = { ...this.p.input.all.rentalMonth }
							this.calcFee(v)
						})
					}
				} else alert.w('일괄입력할 값을 먼저 입력해주세요')
			} else alert.w('옵션을 먼저 생성해주세요')
		},

		changeGroupRentalPrice() {
			const { rentalMonthPrice, rentalPriceInterest } = this.getRentalPrice(
				this.p.input.rentalMonth,
				num(this.p.input.rentalPrice)
			)
			this.rentalMonthPrice = rentalMonthPrice
			this.rentalPriceInterest = rentalPriceInterest
		},
		getRentalPrice(month, salePrice) {
			if (typeof month == 'object') month = month.value
			month = getOptsFromValue(this.rentalRatioOpts, month)
			const { fee, value } = month,
				rentalMonthPrice = Math.round(salePrice / fee / value / 100) * 100
			return {
				rentalMonthPrice,
				rentalPriceInterest: rentalMonthPrice * value,
			}
		},

		setImg(key) {
			setTimeout(() => {
				const file = this.$refs[key][0].files
				uploadFile(file, key, undefined, undefined, this.imgEtcData).then(
					url => (this.p.input[key] = `${s3Url}${/\/.*/.exec(url)[0]}`)
				)
			}, 0)
		},

		checkProduct() {
			//상품 유효성 검사
			const checkKey = [],
				essentailKey = []
			if (this.p.isPossibleNormal) {
				checkKey.push('')
				essentailKey.push(this.inf.slice(4, 10))
				//재고~핫티 수수료까지 필수
				if (!this.productTb.res.length) return false
			}
			if (this.p.isPossibleRental) {
				checkKey.push('rt')
				essentailKey.push([...this.rtinf.slice(4, 6), ...this.rtinf.slice(8, 11)])
				//재고~판매가, 내돈내산가~핫티수수료
				if (!this.rtproductTb.res.length) return false
			}

			let res = true
			checkKey.map((rtkey, i) => {
				const key = essentailKey[i].map(k => k.key),
					isRental = rtkey == 'rt'

				this[rtkey + 'productTb'].res.map(v => {
					let logic = !v.comm.productName
					if (logic) res = false
					this.setError(v, 'productName', logic)
					//상품명 입력 안 한 경우

					logic = v.productOption2 && !v.productOption1
					if (logic) res = false
					this.setError(v, 'productOption1', logic)
					//하위 옵션은 있는데 상위 옵션이 없는 경우
					key.map(k => {
						//필수값 검사
						logic = typeof v[k] == 'undefined' || v[k] === ''
						if (logic) res = false
						this.setError(v, k, logic)
					})
				})
			})
			return res
		},

		getDetailImg() {
			const res = []
			this.quill.getCont().ops.map(({ insert }) => {
				if (insert.image) res.push(insert.image)
				else if (insert.video && insert.video.indexOf('youtube.com/embed/') != -1)
					res.push('youtube=' + insert.video.split('youtube.com/embed/')[1].split('?')[0])
			})
			return res
		},

		getOpt(v, productOptionSequence = 1, productName = '', productNum = '') {
			const opt = {
				saveStatus: v.saveStatus ? 'Y' : 'N',
				rentalMonth: num(getDropdownValue(v.rentalMonth)),
				productOptionSequence,
				productName,
				productNum,
			}
			;[
				'productOptionKey',
				'isChargeAdd',
				'productKey',
				'productOption1',
				'productOption2',
				'selfDealHottCharge',
				'selfDealHottistCharge',
				'seqNo',
				'isTaxFree',
			].map(k => (opt[k] = v[k] ?? ''))
			;[
				'customerPrice',
				'hottCharge',
				'hottistCharge',
				'rentalMonthPrice',
				'rentalMonthPriceInterest',
				'rentalPriceInterest',
				'salePrice',
				'selfDealPrice',
				'sponsoredSupplyPrice',
				'purchasePrice',
				'stock',
			].map(k => (opt[k] = num(v[k])))
			opt.sponsoredHottCharge = opt.selfDealPrice - opt.sponsoredSupplyPrice
			opt.selfDealHottCharge = opt.hottCharge
			opt.selfDealHottistCharge = opt.hottistCharge
			return opt
		},
		save() {
			const e = [],
				input = this.p.input,
				images = {},
				{ productGroup } = copyVar(this.origin)
			let isChangeCode = this.p.oldProductNum != this.p.productNum

			//유효성 검사 필요한 항목들
			const addValidation = copyVar(this.baseInf[1])
			//소비자가~렌탈판매가
			if (!this.p.isPossibleNormal) addValidation.splice(1, 1)
			//일반 상품을 못하면 판매가 검사 안 함
			if (!this.p.isPossibleRental)
				addValidation.splice(3, 1)
				//렌탈 상품을 못하면 렌탈 판매가 검사 안 함
			;[
				{ title: '업체명', key: 'partner' },
				...this.baseInf[0],
				{ title: '상품 한줄 설명', key: 'onelineCont' },
				...addValidation,
			].map((k, i) => {
				if (!input[k.key]) {
					const end = i < 4 ? '을' : '를'
					e.push(k.title + end + ' 입력해주세요')
				}
			})
			//업체명~한줄설명
			if (this.p.isPossibleRental && !input.rentalMonth) e.push('렌탈 개월을 선택해주세요')

			productGroup.type = input.category.join(',')
			if (!productGroup.type) e.push('노출 카테고리를 선택해주세요')

			let prependHashTag = '',
				blankCategory = 0,
				enrollCategory = 0
			;[1, 2, 3].map(k => {
				productGroup['productGroupCategory' + k] = getDropdownValue(input['category' + k])
				if (!productGroup['productGroupCategory' + k]) blankCategory++
				else enrollCategory++

				prependHashTag += input['category' + k].label ?? ''
				prependHashTag += ','
			})
			if (blankCategory == 3) e.push('상세 카테고리를 선택해주세요')
			else if (productGroup.productGroupCategory1 != 1 && enrollCategory != 3)
				e.push('상세 카테고리를 마저 선택해주세요')

			if (productGroup.productGroupCategory1 == 1) {
				//상세 카테고리 없음
				productGroup.productGroupCategorySeq = 1
				prependHashTag = ''
			} else productGroup.productGroupCategorySeq = productGroup.productGroupCategory3
			productGroup.hashTag =
				prependHashTag +
				input.hashTag
					.split(',')
					.map(v => v.trim())
					.filter(v => v)
					.join(',')

			if (!this.p.checkProductCode()) e.push('상품 코드는 영문만 입력해주세요')

			if (this.p.isEssentialChangeCode) {
				if (this.p.oldProductNum == this.p.productNum)
					e.push(
						'상품 코드를 변경하지 않고는 변경할 수 없는 상품 옵션이 변경되었습니다.\r\n상품 코드를 변경해주세요'
					)
				else {
					if (!this.p.isCheckDup) e.push('상품 코드 중복확인을 해주세요')
				}
			}

			if (!this.checkProduct()) e.push('상품 영역에 비어 있는 값이 있는지 확인해주세요')

			if (input.mainImage) images.main = input.mainImage
			else e.push('메인 이미지를 입력해주세요')
			images.price = input.priceImage
			const detailImage = this.getDetailImg()
			if (detailImage.length) images.detail = detailImage
			else e.push('상세 페이지에 이미지 또는 유튜브 링크를 입력해주세요')

			productGroup.productGroupName = input.displayProductName

			if (e.length) alert.w(e)
			else {
				//유효성 검사가 필요 없는 항목들
				//store.companyName = input.partner.label
				//store.storeSeq = input.partner.value

				productGroup.rentalMonth = num(getDropdownValue(input.rentalMonth))
				productGroup.deliveryPriceType = input.deliveryType
				//rentalPriceInterest, rentalMonthPriceInterest

				productGroup.fee = num(input.hottistCharge)
				productGroup.feeString = productGroup.fee + '%'
				productGroup.productCode = this.p.productNum

				//키값 그대로 옮길 항목
				;[
					'brandName',
					'onelineCont',
					'basicClass',
					'productGroupClass',
					'isSelfDeal',
					'isApplySelfDeal',
					'productType',
					'isCombinedDelivery',
				].map(k => (productGroup[k] = input[k]))
				if (!this.productGroupIdx)
					productGroup.productGroupClass = productGroup.basicClass

					//숫자 형식으로 옮길 항목
				;[
					'customerPrice',
					'salePrice',
					'selfDealPrice',
					'rentalPrice',
					'deliveryPrice',
					'deliveryFreePrice',
					'bundleDeliveryCount',
					'jejuDeliveryPrice',
					'etcDeliveryPrice',
					'monthlyMaxCount',
				].map(k => (productGroup[k] = num(input[k])))

				//추가 예외 처리
				productGroup.rentalMonthPrice = productGroup.rentalPrice
					? Math.round(productGroup.rentalPrice / productGroup.rentalMonth)
					: 0
				//이게 왜 따로 있냐;; 이거 쓰나;;
				productGroup.rentalPriceInterest = num(this.rentalPriceInterest)
				productGroup.rentalMonthPriceInterest = num(this.rentalMonthPrice)
				let salePrice = input.productType == '3' ? input.rentalPrice : input.salePrice
				salePrice = num(salePrice)
				productGroup.hottistSalesFee = Math.floor((salePrice * productGroup.fee) / 100)
				if (input.jejuDeliveryType == 'none') productGroup.jejuDeliveryPrice = -1
				else if (input.jejuDeliveryType == 'noCharge') productGroup.jejuDeliveryPrice = 0
				if (input.etcDeliveryType == 'none') productGroup.etcDeliveryPrice = -1
				else if (input.etcDeliveryType == 'noCharge') productGroup.etcDeliveryPrice = 0

				const data = { productGroup, images }
				//마지막으로 상품 배열 생성
				const product = {}
				let allOptionCt = 0,
					allTaxFreeOptionCt = 0
				;['', 'rt'].map(key => {
					const res = []
					if (!this[key + 'productTb'].res.length) {
						if (key) data.rentalProducts = res
						else data.products = res
						return
					}

					const productDeliveryType = key ? input.productDeliveryType : 1
					let oldComm = '',
						idx = 0
					this[key + 'productTb'].res.map((v, i) => {
						allOptionCt++
						let opt
						if (oldComm != v.comm) {
							//각 상품의 첫 번째 옵션인 경우
							oldComm = v.comm
							let { productKey, productNum } = v
							if (isChangeCode) productNum = key + this.p.productNum + (res.length + 1)

							const { showSelfDeal, showSponsoredSupply, productName } = v.comm
							let influencerSelectable
							if (showSponsoredSupply) influencerSelectable = showSelfDeal ? 'A' : 'O'
							else influencerSelectable = showSelfDeal ? 'M' : 'X'

							opt = this.getOpt( v, i + 1, productName, productNum )
							res[idx++] = {
								productKey,
								influencerSelectable,
								productDeliveryType,
								productName,
								productNum,
								productSequence: idx,
								showSelfDeal,
								showSponsoredSupply,
								productOptions: [opt],
							}
						} else {
							//옵션이 두 번째 이후인 경우
							const parent = res[idx - 1],
								{ productName, productNum } = parent
							opt = this.getOpt( v, i + 1, productName, productNum )
							parent.productOptions.push(opt)
						}
						if(opt.isTaxFree) allTaxFreeOptionCt++
					})
					if (key) data.rentalProducts = res
					else data.products = res
				})
				if(!allTaxFreeOptionCt)
					data.productGroup.taxType = 1
				else if(allOptionCt==allTaxFreeOptionCt)
					data.productGroup.taxType = 2
				else
					data.productGroup.taxType = 3
				this.$emit('save', data)
				//store 정보 추가 해야 한다
			}
		},
		downloadProductOpts() {
			const { productGroupName, productGroupIdx, productCode } = this.p.origin.productGroup,
				ex = new excel(undefined, `${productGroupName}(${productGroupIdx}) 상품 옵션`)
			postApi('api/backoffice/productSelect/downloadProductDetail', {
				productGroupIdx,
				productNum: productCode,
				isExcel: 1,
				excelInfo: ex.get(),
			}).then(() => alert.excel())
		},
	},
	watch: {
		rentalRatioOpts: {
			deep: true,
			handler(res) {
				const rentalMonthInf = {
					title: '렌탈개월',
					model: 'dropdown',
					options: this.rentalRatioOpts,
					key: 'rentalMonth',
					size: 5,
				}
				//this.rtinf[6] = rentalMonthInf
				this.$set(this.rtinf, 6, rentalMonthInf)
				//위에 data 단에서 넣으니까 어떻게 해도 rentalRatioOpts를 인식을 못한다...
			},
		},
		origin: {
			deep: true,
			handler(res) {
				const input = this.p.input,
					{ images, productGroup, store } = res

				input.brandName = productGroup.brandName
				input.displayProductName = productGroup.productGroupName
				input.onelineCont = productGroup.onelineCont
				input.customerPrice = numFormat(productGroup.customerPrice)
				input.salePrice = numFormat(productGroup.salePrice)
				input.selfDealPrice = numFormat(productGroup.selfDealPrice)
				input.rentalPrice = numFormat(productGroup.rentalPrice)
				input.rentalMonth = getOptsFromValue(this.rentalRatioOpts, productGroup.rentalMonth)
				this.changeGroupRentalPrice()

				input.jejuDeliveryPrice = numFormat(productGroup.jejuDeliveryPrice)
				input.etcDeliveryPrice = numFormat(productGroup.etcDeliveryPrice)
				input.isCombinedDelivery = productGroup.isCombinedDelivery
				input.basicClass = productGroup.basicClass
				input.productGroupClass = productGroup.productGroupClass
				input.deliveryType = productGroup.deliveryPriceType
				input.deliveryPrice = numFormat(productGroup.deliveryPrice)
				input.deliveryFreePrice = numFormat(productGroup.deliveryFreePrice)
				;['jeju', 'etc'].map(k => {
					if (productGroup[k + 'DeliveryPrice'] == 0) input[k + 'DeliveryType'] = 'noCharge'
					else if (productGroup[k + 'DeliveryPrice'] != -1) {
						input[k + 'DeliveryType'] = 'possible'
						input[k + 'DeliveryPrice'] = numFormat(productGroup[k + 'DeliveryPrice'])
					} else {
						input[k + 'DeliveryType'] = 'none'
						input[k + 'DeliveryPrice'] = 0
					}
				})
				input.isBundleDelivery = productGroup.bundleDeliveryCount > 0
				input.bundleDeliveryCount = numFormat(productGroup.bundleDeliveryCount)
				input.monthlyMaxCount = numFormat(productGroup.monthlyMaxCount)

				const hashTag = productGroup.hashTag.split(',')
				//trim은 입력할 때 해줄 것
				input.category = productGroup.type.split(',')
				if (productGroup.productGroupCategorySeq == 1) {
					//상세 카테고리 없음
					productGroup.productGroupCategory1 = 1
					productGroup.productGroupCategory3 = null
				}
				this.getCategoryInfo(() => {
					;[3, 2, 1].map(k => {
						const opts = this['categoryOpts' + k],
							opt = getOptsFromValue(opts, productGroup['productGroupCategory' + k])
						if (opt.length) input['category' + k] = opt

						if (getOptsFromLabel(opts, hashTag[k - 1])) hashTag.splice(k - 1, 1)
						//해시 태그랑 카테고리 라벨이랑 일치하면 해시태그 제거
					})
					input.hashTag = hashTag.join(',')
				})

				input.isSelfDeal = productGroup.isSelfDeal
				input.isApplySelfDeal = productGroup.isApplySelfDeal
				input.hottistCharge = productGroup.fee
				input.productType = productGroup.productType
				this.p.changeProductType()

				this.p.oldProductNum = productGroup.productCode
				this.p.productNum = productGroup.productCode

				if (res.rentalProducts.length)
					this.p.input.productDeliveryType = res.rentalProducts[0].productDeliveryType

				const { products, rentalProducts } = res
				this.res = copyVar(products)
				this.rtres = copyVar(rentalProducts)

				//이미지 조정
				input.mainImage = images.main
				input.priceImage = images.price
				setTimeout(() => {
					this.quill.setCont(images.detail)
				}, 500)
			},
		},
	},
	computed: {
		inputAllInf() {
			const end = this.isPartner ? 7 : 8
			return [
				...this.inf.slice(4, end),
				//재고수량, 소비자가, 판매가, 내돈내산가
			]
		},
		categoryComp1() {
			return this.categoryOpts1 ?? []
		},
		categoryComp2() {
			let res = []
			if (Array.isArray(this.categoryOpts2))
				res = this.categoryOpts2.filter(v => v.parent == this.p.input.category1.value)
			return res
		},
		categoryComp3() {
			let res = []
			if (Array.isArray(this.categoryOpts3))
				res = this.categoryOpts3.filter(v => v.parent == this.p.input.category2.value)
			return res
		},
		tempPath() {
			return `${new Date().getTime()}_${Math.random().toString(36).substr(2, 8)}`
		},
		imgEtcData() {
			const data = { productGroupIdx: 0, tempPath: '' }
			if (this.productGroupIdx) data.productGroupIdx = this.productGroupIdx
			else data.tempPath = this.tempPath
			return data
		},
		enrollDate() {
			if (!this.origin) return false
			const { products, rentalProducts } = this.origin
			if (products.length) return products[0].enrollDate
			if (rentalProducts.length) return rentalProducts[0].enrollDate
			return false
		},
	},
}
</script>

<style lang="scss">
.mainImgWrap {
	display: inline-block;
}

.mainImg {
	max-width: 800px;
}

.imgBtn {
	border: 2px dashed var(--gray);
	border-radius: 10px;

	svg {
		top: 25%;
		left: 25%;
	}
}
</style>
