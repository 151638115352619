<template>
	<div>
		<product
			@save="save"
			:inf="inf"
			:isPartner="false"
			:origin="origin"
			:pr="ths"
			:productGroupIdx="productGroupIdx"
			:rentalRatioOpts="rentalRatioOpts"
		>
			<template #partnerSlot>
				<selectPartner @change="changePartner" :isRemovable="!isEdit" :pr="ths" :showPic="true" />
			</template>

			<template #baseInfoFooterSlot>
				<h6>상품 구분</h6>
				<b-form-radio
					v-for="v in productTypeOpts"
					v-model="input.productType"
					name="productType"
					@change="changeProductType"
					:disabled="isEdit"
					:value="v.value"
				>
					{{ v.label }}
				</b-form-radio>

				<h6 class="mt-6">내돈내산 가능 여부</h6>
				<b-form-checkbox
					v-model="input.isSelfDeal"
					@change="isSelfDeal => (input.isApplySelfDeal = isSelfDeal)"
				>
					구매 가능</b-form-checkbox
				>
				<b-form-checkbox class="mb-4" v-model="input.isApplySelfDeal" :disabled="!input.isSelfDeal">
					딜 가능</b-form-checkbox
				>
			</template>

			<template #feeNClassSlot>
				<h6>대표 수수료</h6>
				<div class="flex">
					<b-form-input
						class="w-40 text-right"
						v-model.trim="input.hottistCharge"
						name="hottistCharge"
						placeholder="대표수수료 입력"
						@keyup="input.hottistCharge = numFormat(input.hottistCharge)"
					/>
					&nbsp;&nbsp;
					<p class="mt-1.5">%</p>
				</div>

				<div
					v-for="v in [
						{
							title: '기본',
							key: 'basicClass',
							exp: '매월 말일 10일 전 협찬재고 0인 상품 리셋될 때, 기본등급으로 조정',
							vIf: () => 1,
						},
						{
							title: '변동',
							key: 'productGroupClass',
							exp: '신규 등록 후 6일간 등급 고정 → 이후 6의 배수 날짜에 자동 하향 (6,12,18,30)',
							vIf: () => isEdit,
						},
					]"
					v-if="v.vIf()"
				>
					<expTitle class="mt-3" :exp="v.exp" :title="'상품 노출 등급(' + v.title + '등급)'" />
					<div class="flex">
						<b-form-radio
							v-for="c in ['1', 'a', 'b', 'c', 'd', 'e', 'z']"
							class="mr-4"
							v-model="input[v.key]"
							:name="v.key"
							:value="c"
						>
							{{ c }}등급
						</b-form-radio>
					</div>
				</div>
			</template>

			<template #productCodeSlot>
				<div class="mb-4">
					<p6>상품 코드</p6>
					<div class="flex mb-3">
						<b-form-input
							class="w-80 mr-3"
							v-model.trim="productNum"
							name="productNum"
							@change="isCheckDup = false"
						/>
						<b-button class="w-30 h-9" @click="checkDup" :variant="isCheckDup ? 'primary' : 'danger'"
							>중복확인
						</b-button>
					</div>
					<p6 :class="{ 'text-danger': isEssentialChangeCode || !isCheckDup }">
						※ 소비자가, 판매가, 핫티수수료, 매입가를 수정하거나 신규 옵션을 등록할 경우 상품코드를 변경해야
						합니다<br />
						상품 삭제 시 상품 묶음 전체를 삭제 해야 적용됩니다
					</p6>
				</div>
			</template>

			<template #inputAllSlot>
				<div class="inline-block w-1/3 pl-4">
					<h6 class="mb-3.5 pl-4">옵션 수수료 입력</h6>
					<div
						v-for="v in [
							{
								title: '협찬 공급가',
								key: 'supplyPriceAll',
							},
							{
								title: '매입가',
								key: 'purchasePriceAll',
							},
							{
								title: '핫티스트 수수료율(%)',
								key: 'hottistChargeAll',
							},
						]"
						class="flex mt-2 pl-4"
					>
						<h6 class="w-1/2 lh-9" v-text="v.title" />
						<b-form-input
							v-model.trim="input[v.key]"
							:name="'createProduct' + v.key"
							:placeholder="v.title + ' 입력'"
							@keyup="input[v.key] = numFormat(input[v.key])"
						/>
					</div>
					<div class="pl-4 mt-4">
						<b-button class="w-1/1" @click="setChargeAll">수수료 적용</b-button>
					</div>
				</div>

				<div v-if="isPossibleRental" class="flex w-1/3">
					<div v-show="input.productType == 4" class="mr-8">
						<p6>적용 대상</p6>
						<b-form-radio
							v-for="v in [
								{ title: '일반 옵션', val: 0 },
								{ title: '렌탈 옵션', val: 1 },
							]"
							v-model="input.allTarget"
							name="allTarget"
							:value="v.val"
						>
							{{ v.title }}
						</b-form-radio>
					</div>

					<div>
						<p6>렌탈 구분</p6>
						<b-form-radio
							v-for="v in productDeliveryTypeOpts"
							v-model="input.productDeliveryType"
							name="productDeliveryType"
							:value="v.value"
						>
							{{ v.label }}
						</b-form-radio>
					</div>
				</div>
				<b-button class="w-1/1 mt-4" @click="calcSelfDealPriceStart" variant="info"
					>내돈내산가 계산하기</b-button
				>
			</template>

			<template #historySlot>
				<div v-if="history.length">
					<h6>상품 옵션 히스토리</h6>
					<collapse title="옵션 히스토리 불러오기">
						<collapse
							v-for="(v, pi) in history"
							:title="`옵션코드 ${v.histSeq} / 상품코드 ${v.productCode} / 등록일 ${v.enrollDate.replace(
								'T',
								' '
							)}`"
						>
							<tb :inf="historyInf" :pr="ths" :res="v" limit="9999" nav="none">
								<template #stock="{ h, v, i }">
									<relationStock :model="`history.${pi}.list.${i}.stock`" :pr="ths" :seq="v.seqNo" />
								</template>
							</tb>
							<div class="w-100 mx-auto mb-2">
								<b-button
									v-for="b in [
										{ label: '재고 저장', variant: 'primary', func: saveHistoryStock },
										{ label: '상품그룹에 연결', variant: 'info', func: saveHistoryProduct },
									]"
									class="w-46 mx-2"
									@click="b.func(v)"
									:variant="b.variant"
									>{{ b.label }}
								</b-button>
							</div>
						</collapse>
					</collapse>
				</div>
			</template>

			<template #productFooter>
				<div class="px-4 flex-center mb-10 w-92 mx-auto">
					<b-button class="w-40 block mx-auto" @click="startSave"
						>상품 {{ isEdit ? '내용 수정' : '등록' }}
					</b-button>
					<b-button v-if="isEdit" class="w-40 block mx-auto" @click="updateDealsPopup" variant="info"
						>딜 연동</b-button
					>
				</div>
			</template>
		</product>
		<productAddPopup
			:baseInf="inf"
			:isPossibleNormal="isPossibleNormal"
			:isPossibleRental="isPossibleRental"
			:pr="ths"
		/>
		<sponsoredPopup v-if="!isEdit" :pr="ths" />
	</div>
</template>

<script>
import js from 'comp/local/productJs'
import product from 'comp/local/productTag'
import expTitle from 'comp/local/productAddExpTitle'
import selectPartner from 'comp/local/selectPartner'
import collapse from 'comp/collapse'
import relationStock from 'comp/local/productAddRelationStock'
import productAddPopup from 'pages/productAddPopup'
import sponsoredPopup from 'pages/sponsoredPopup'

export default {
	props: { productGroupIdx: { default: '' } },

	//mixins: [product],
	components: {
		product,
		expTitle,
		selectPartner,
		relationStock,
		collapse,

		productAddPopup,
		sponsoredPopup,
	},
	data() {
		js.setIsPartner(false, this)
		return js.getData()
	},
	created() {
		if (this.productGroupIdx) this.isEdit = true

		js.getConfig()

		if (this.productGroupIdx) {
			this.isEdit = true

			const input = this.input,
				data = { productGroupIdx: this.productGroupIdx }
			postApi('productSelect/productDetail', data).then(res => {
				this.origin = res
				const partner = {
					label: res.store.companyName,
					value: res.store.storeSeq,
					contractPicName: res.store.contractPicName,
				}
				this.selectPartner.setPartner(partner)
				input.partner = partner
				//나머지 상품그룹, 상품, 이미지 조정은 하위 productTag에서

				if (res.rentalProducts.length) input.productDeliveryType = res.rentalProducts[0].productDeliveryType
			})

			postApi('api/backoffice/productSelect/productOptionHistoryList', data).then(res => {
				this.history = res.products.map(v => {
					v.list = v.productOptions.map(p => {
						;['stock', 'customerPrice', 'salePrice', 'selfDealPrice', 'hottistCharge', 'hottCharge'].map(
							k => (p[k] = numFormat(p[k]))
						)
						return p
					})
					delete v.productOptions
					v.paging = {
						totalCnt: v.list.length,
						currentPage: 0,
						totalPage: 1,
					}
					return v
				})
			})
		} else {
			this.isEdit = false
			this.changeProductType()
			setTimeout(() => {
				this.childTag.getCategoryInfo()
			}, 500)
		}
	},
	methods: {
		changePartner(item) {
			this.input.partner = item
		},
		changeProductType() {
			let isPossibleNormal = true,
				isPossibleRental = true
			switch (this.input.productType * 1) {
				case 1:
					//일반
					isPossibleRental = false
					break
				case 2:
					//해외직구
					isPossibleRental = false
					break
				case 3:
					//렌탈
					isPossibleNormal = false
					break
				case 4:
					//렌탈+일반
					break
				case 5:
					//주류
					isPossibleRental = false
			}

			this.input.allTarget = 0
			switch (this.input.productType * 1) {
				case 3:
					this.input.allTarget = 1
				case 4:
					this.isPossibleSetDeliveryPrice = false
					this.input.deliveryType = 1
					break
				default:
					this.isPossibleSetDeliveryPrice = true
			}
			this.isPossibleNormal = isPossibleNormal
			this.isPossibleRental = isPossibleRental
		},
		setChargeAll() {
			//const { sponsoredSupplyPrice, hottCharge, hottistCharge } = this.p.input
			//this.p.input.sponsoredSupplyPrice = sponsoredSupplyPrice
			const isRental = this.input.allTarget,
				key = isRental ? 'rt' : ''

			this.supplyPrice = this.input.supplyPriceAll
			this.purchasePrice = this.input.purchasePriceAll

			this.hottistCharge = this.input.hottistChargeAll
			if (this.hottistCharge !== '') this.isEssentialChangeCode = true
			this.childTag[key + 'productTb'].res.map(v => {
				v.isEditSupply = this.supplyPrice === ''
				v.isEditCharge = this.hottistCharge === ''
				v.purchasePrice = comma( this.purchasePrice )
				this.calcFee(v, isRental)
			})
		},

		saveHistoryStock(item) {
			//재고 저장
			confirm('재고를 저장하시겠습니까?', () => {
				const productOptions = item.list.map(v => {
					return {
						productOptionSeq: v.seqNo,
						stock: num(v.stock),
					}
				})
				putApi('api/backoffice/productHandle/updateProductOptionStock', { productOptions }).then(() => {
					alert.s('재고를 저장했습니다')
					refresh()
				})
			})
		},
		saveHistoryProduct(item) {
			//상품그룹에 연결
			confirm('상품코드 ' + item.productCode + '를 상품그룹에 연결하시겠습니까?', () => {
				putApi('api/backoffice/productHandle/updateProductGroupConnectStatus', {
					productGroupIdx: this.productGroupIdx,
					histSeq: item.histSeq,
				}).then(() => {
					alert.s('상품 연결에 성공했습니다')
					refresh()
				})
			})
		},

		calcSelfDealPriceStart() {
			if (!this.selectPartner.partner) return alert.w('파트너사를 먼저 선택해주세요')
			const isSocialbean = this.selectPartner.partner.value == 84

			let e = 0
			if (this.isPossibleNormal) e += this.calcSelfDealPrice(this.childTag.productTb.res, isSocialbean)
			if (this.isPossibleRental) e += this.calcSelfDealPrice(this.childTag.rtproductTb.res, isSocialbean)
			if (e)
				alert.w(`${e}개 제품의 내돈내산가를 계산하지 못했습니다.\r\n제품의 판매가, 협찬 공급가를 확인해주세요.`)
		},
		calcSelfDealPrice(arr, isSocialbean = false) {
			let e = 0
			//this.childTag.productTb.res[0].stock = 102
			arr.map(v => {
				let { sponsoredSupplyPrice, salePrice } = v
				//협찬 공급가, 판매가
				sponsoredSupplyPrice = num(sponsoredSupplyPrice)
				salePrice = num(salePrice)
				if (!sponsoredSupplyPrice || !salePrice) return e++

				/*let selfDealSupply = 90,
				 selfDealPrice
				 if (!isSocialbean) {
				 //const allFee = 100% - sponsoredSupplyPrice / salePrice
				 //총수수료
				 //selfDealSupply = 100% - (allFee - 10%)
				 //?? 이걸 왜 이렇게 복잡하게 꼬아서 쓰지;;
				 selfDealSupply = Math.round((sponsoredSupplyPrice / salePrice + 0.1) * 10000) / 100
				 //소숫점 둘째 자리까지 반올림

				 if (selfDealSupply < 90) selfDealPrice = salePrice * 0.9
				 else selfDealPrice = (salePrice * selfDealSupply) / 100
				 }
				 //소셜빈이면 판매가의 90%로 고정
				 else*/
				let selfDealPrice = salePrice * 0.9
				selfDealPrice = Math.ceil(selfDealPrice / 100) * 100
				//백의 자리까지 올림

				v.selfDealPrice = numFormat(selfDealPrice)
			})
			return e
		},
		calcFee(list) {
			this.childTag.calcFee(list)
		},
		checkProductCode() {
			return !(/[^a-z-_]/gi.test(this.productNum) || this.productNum === '')
		},
		checkDup() {
			if (this.checkProductCode()) {
				if (!this.isCheckDup) {
					postApi('productSelect/checkProductCode', { productCode: this.productNum }).then(res => {
						if (res.available) {
							alert.s('사용 가능한 상품 코드입니다')
							this.isCheckDup = true
						} else alert.w('이미 사용 중인 상품 코드입니다')
					})
				}
			} else alert.w('상품 코드는 영문만 입력할 수 있습니다')
		},

		startSave() {
			this.childTag.save()
		},
		save(data) {
			data.store = {
				companyName: this.input.partner.label,
				storeSeq: this.input.partner.value,
			}
			let api, title
			if (this.isEdit) {
				api = putApi('api/backoffice/productHandle/updateProduct', data)
				title = '수정'
			} else {
				api = postApi('api/backoffice/productHandle/registerProduct', {
					tempPath: this.childTag.tempPath,
					productDetail: data,
				})
				title = '등록'
			}
			api.then(res => {
				if (this.isEdit) {
					alert.s(title + '완료되었습니다')
					refresh()
				} else {
					this.sponsoredPopup.open({
						comName: res.companyName,
						dealName: res.productGroupName,
						matchingClass: '',
						follower: '',
						matchingStatus: '',
						productMatchingInfoSeq: null,
						productGroupIdx: res.productGroupIdx,
					})
				}
			})
		},
		updateDealsPopup() {
			this.productAddPopup.open(this.origin)
		},
	},
	computed: {},
}
</script>
