<template>
	<div class="flex">
		<input
			class="form-control text-right pr-5 pl-0"
			v-model.trim="compModel"
			name="stockBox"
			@input="compModel = numFormat(compModel)"
			type="text"
		/>
		<b-icon
			v-if="seq"
			class="cursor-pointer absolute right-1 top-3"
			@click="showStockRelation"
			font-scale="1.2"
			icon="info-circle"
		/>
		<div v-show="isShow" class="stockTable absolute -right-100 z-50 top-0 w-100 bg-white px-2 pt-2">
			<h6>이 재고를 수정하면 같이 수정되는 상품옵션</h6>
			<b-icon class="absolute top-2 right-3 cursor-pointer" @click="isShow = false" icon="x" scale="2" />
			<tb :inf="inf" :res="res" limit="9999" nav="none" />
		</div>
	</div>
</template>

<script>
import calcParent from 'comp/local/calcParent.js'

export default {
	mixins: [calcParent],
	props: { seq: { default: '' } },
	data() {
		return {
			isShow: false,
			productKey: '',
			productOptionKey: '',
			inf: [
				{ title: '상품코드', key: 'productNum', size: 3 },
				{ title: '상품명', key: 'productName', size: 3 },
				{ title: '옵션1', key: 'productOption1', size: 3 },
				{ title: '옵션2', key: 'productOption2', size: 3 },
			],
			res: [],
		}
	},
	methods: {
		showStockRelation() {
			postApi('api/backoffice/productSelect/stockSharingProductOptionList', {
				productOptionSeq: this.seq,
			}).then(res => {
				this.productKey = res.productKey
				this.productOptionKey = res.productOptionKey
				this.res = getTempPaging(res.productOptions, res.productOptions.length)
				this.isShow = true
			})
		},
	},
}
</script>

<style>
.stockTable {
	border: 1px solid var(--gray);
	border-radius: 5px;
}
</style>
