<template>
	<div class="tb">
		<table class="table b-table table-sm border">
			<thead class="thead-light">
				<tr>
					<th v-for="h in inf" :class="`w-${h.size ? h.size : 1}`" class="align-middle text-center py-2">
						<p v-html="h.title" />
						<b-button
							v-if="h.tooltip"
							class="tbTooltip"
							v-b-tooltip.hover.html
							:title="h.tooltip"
							size="sm"
							variant="no"
						>
							<!--헤드에 표시될 툴팁이 있는 경우-->
							<b-icon icon="info-circle" />
						</b-button>
					</th>
				</tr>
			</thead>
			<tbody v-if="res.length">
				<tr v-for="(v, i) in res">
					<td class="text-center">
						<div class="removeBtn" @click.stop.prevent="deleteLine(v, i)">-</div>
						<div class="addBtn" @click.stop.prevent="addLine(v, i)">+</div>
					</td>
					<td v-if="v.j == 0" :rowspan="v.j == 0 ? v.comm.len : 1">
						<input
							:class="v.productNameClass"
							class="form-control"
							v-model.trim="v.comm.productName"
							type="text"
						/>
					</td>
					<td>
						<input
							:class="v.productOption1Class"
							class="form-control"
							v-model.trim="v.productOption1"
							type="text"
						/>
					</td>
					<td>
						<input
							:class="v.productOption2Class"
							class="form-control"
							v-model.trim="v.productOption2"
							type="text"
						/>
					</td>
					<td>
						<div class="flex">
							<input
								:class="v.stockClass"
								class="stockBox form-control text-right"
								v-model.trim="v.stock"
								name="stockBox"
								@input="v.stock = numFormat(v.stock)"
								type="text"
							/>
							<b-icon
								v-if="v.seqNo"
								class="cursor-pointer absolute right-1 top-3"
								@click="showStockRelation(v)"
								font-scale="1.2"
								icon="info-circle"
							/>
							<div
								v-if="v.stockRes"
								class="stockTable absolute -right-100 z-50 top-0 w-100 bg-white px-2 pt-2"
							>
								<h6>이 재고를 수정하면 같이 수정되는 상품옵션</h6>
								<b-icon
									class="absolute top-2 right-3 cursor-pointer"
									@click="v.stockRes = ''"
									icon="x"
									scale="2"
								/>
								<tb :inf="stockInf" :res="v.stockRes" limit="9999" nav="none" />
							</div>
						</div>
					</td>
					<td v-if="!isRental">
						<input
							:class="v.customerPriceClass"
							class="form-control text-right"
							v-model.trim="v.customerPrice"
							name="customerPrice"
							@input="inputNum(v, 'customerPrice')"
							type="text"
						/>
					</td>

					<td>
						<input
							:class="v.salePriceClass"
							class="form-control text-right"
							v-model.trim="v.salePrice"
							name="salePrice"
							@input="inputNum(v, 'salePrice')"
							type="text"
						/>
					</td>

					<td v-if="isRental">
						<select
							:class="v.rentalMonthClass"
							class="form-control"
							v-model="v.rentalMonth"
							@change="calcFee(v)"
						>
							<option v-for="o in rentalRatioOpts" :value="o">{{ o.label }}</option>
						</select>
					</td>
					<td v-if="isRental">
						<p class="text-right px-2">{{ numFormat(v.rentalMonthPrice) }}</p>
					</td>
					<td>
						<input
							:class="v.selfDealPriceClass"
							class="form-control text-right"
							v-model.trim="v.selfDealPrice"
							name="selfDealPrice"
							@input="inputNum(v, 'selfDealPrice')"
							type="text"
						/>
					</td>

					<td>
						<input
							:class="v.sponsoredSupplyPriceClass"
							class="form-control text-right"
							v-model.trim="v.sponsoredSupplyPrice"
							name="sponsoredSupplyPrice"
							@input="inputNum(v, 'sponsoredSupplyPrice')"
							type="text"
						/>
					</td>
					<td>
						<input
							:class="v.purchasePrice"
							class="form-control text-right"
							v-model.trim="v.purchasePrice"
							name="purchasePrice"
							@input="inputNum(v, 'purchasePrice')"
							type="text"
						/>
					</td>
					<td>
						<input
							:class="v.hottistChargeClass"
							class="form-control text-right"
							v-model.trim="v.hottistCharge"
							name="hottistCharge"
							@input="inputNum(v, 'hottistCharge')"
							type="text"
						/>
					</td>

					<td>
						<p class="text-right px-2">{{ numFormat(v.hottCharge) }}</p>
					</td>
					<td class="text-center">
						<input v-model="v.isTaxFree" type="checkbox" @change="$emit('changeCode', true)"/>
					</td>
					<td class="text-center">
						<input v-model="v.saveStatus" type="checkbox" />
					</td>
					<td class="text-center">
						<input v-model="v.isChargeAdd" type="checkbox" />
					</td>
					<td v-if="v.j == 0" class="text-center" :rowspan="v.j == 0 ? v.comm.len : 1">
						<input v-model="v.comm.showSponsoredSupply" type="checkbox" />
					</td>
					<td v-if="v.j == 0" class="text-center" :rowspan="v.j == 0 ? v.comm.len : 1">
						<input v-model="v.comm.showSelfDeal" type="checkbox" />
					</td>
					<td v-if="v.j == 0" class="text-center" :rowspan="v.j == 0 ? v.comm.len : 1">
						<div class="btn p-0" @click.stop.prevent="changeSequence(true, v)">
							<b-icon :icon="'caret-up-fill'" font-scale="1" />
						</div>
						<div class="btn p-0" @click.stop.prevent="changeSequence(false, v)">
							<b-icon :icon="'caret-down-fill'" font-scale="1" />
						</div>
					</td>
				</tr>
			</tbody>
			<tbody v-else>
				<tr>
					<td class="text-center py-4" :colspan="inf.length">표시할 항목이 없습니다</td>
				</tr>
			</tbody>
		</table>
		<div class="allMoreBtn" @click.stop.prevent="addLine()">+</div>
	</div>
</template>

<script>
export default {
	props: {
		cName: { default: 'productTb' },
		inf: { default: [] },
		ores: { default: [] },
		isRental: { default: false },
		rentalRatioOpts: { default: [] },
	},
	data() {
		return {
			res: [],

			stockInf: [
				{ title: '상품코드', key: 'productNum', size: 3 },
				{ title: '상품명', key: 'productName', size: 3 },
				{ title: '옵션1', key: 'productOption1', size: 3 },
				{ title: '옵션2', key: 'productOption2', size: 3 },
			],
		}
	},
	methods: {
		setOpt(v) {
			this.res = v
		},
		getRentalPrice(month, salePrice) {
			if (typeof month == 'object') month = month.value
			month = getOptsFromValue(this.rentalRatioOpts, month)
			const { fee, value } = month,
				rentalMonthPrice = Math.round(salePrice / fee / value / 100) * 100
			return {
				rentalMonthPrice,
				rentalPriceInterest: rentalMonthPrice * value,
			}
		},
		calcFee(list) {
			this.p.calcFee(list)
		},
		inputNum(v, k) {
			v[k] = numFormat(v[k])

			switch (k) {
				case 'sponsoredSupplyPrice':
					v.isEditSupply = true
					break
				case 'hottistCharge':
					v.isEditCharge = true
				case 'salePrice':
				case 'rentalMonth':
					this.$emit('changeCode', true)
					break
				case 'customerPrice':
				case 'purchasePrice':
					this.$emit('changeCode', true)
				case 'selfDealPrice':
					return
				//소비자가, 내돈내산가는 수수료 계산하지 않음
			}
			//나머지 수정사항에 대해서는 수수료 계산
			this.calcFee(v)
		},

		deleteLine(v, i) {
			//옵션 지우기
			v.comm.len--
			if (v.j)
				//첫 번째 옵션이 아닌 경우
				this.res.splice(i, 1)
			else if (v.comm.len) {
				//첫 번째 옵션을 지우는데 다음 옵션이 남아있는 경우
				this.res[i + 1].j = 0
				//다음 옵션을 첫 번째로 취급
				this.res.splice(i, 1)
			}
			//첫 번째 옵션을 지우는데 다음 옵션이 안 남아있는 경우는 사실 여기까지 못 와야 하는데..
			else this.res.splice(i, 1)
		},
		addLine(v, i) {
			if (v) {
				//바로 위의 아이템 일부 복사
				const item = { ...v, j: 1, productOption1: '', productOption2: '' }
				delete item.productKey
				delete item.productOptionKey
				delete item.seqNo
				this.res.splice(i + 1, 0, item)
				v.comm.len++
			} else {
				i = this.res.length
				this.res.push({
					comm: {
						productSequence: '',
						showSelfDeal: true,
						showSponsoredSupply: true,
						productName: '',

						len: 1,
					},
					...this.p.baseItem,
					productName: '',
					productOption1: '',
					productOption2: '',
					productNum: i,
					//어차피 상품 코드를 변경 해야 해서 이건 이따가 변경된 코드로 생성된다
					isRental: this.isRental,
					j: 0,
				})
				//가장 아래 큰 줄기 추가
			}
			this.$emit('changeCode', true)
		},
		changeSequence(isUp, v) {
			//옵션 순서 변경. 이제 하위 옵션은 변경하지 못하므로 v는 무조건 상품의 첫 번째 옵션이 온다
			const idx = this.res.indexOf(v),
				ct = v.comm.len
			let toIdx = 0
			//l(isUp, v)
			if (isUp) {
				if (!idx) return
				//up인데 첫 번째 상품인 경우
				toIdx = idx - this.res[idx - 1].comm.len
			} else {
				if (idx + ct == this.res.length) return
				//down인데 마지막 상품인 경우
				toIdx = idx + this.res[idx + ct].comm.len
			}
			this.res.splice(toIdx, 0, ...this.res.splice(idx, ct))
		},

		showStockRelation(v) {
			postApi('api/backoffice/productSelect/stockSharingProductOptionList', {
				productOptionSeq: v.seqNo,
			}).then(res => {
				v.productKey = res.productKey
				v.productOptionKey = res.productOptionKey
				v.stockRes = getTempPaging(res.productOptions, res.productOptions.length)
			})
		},
	},
	watch: {
		ores: {
			deep: true,
			handler(ores) {
				const res = [],
					numKey = [
						'stock',
						'customerPrice',
						'salePrice',
						'selfDealPrice',
						'sponsoredSupplyPrice',
						'purchasePrice',
					]

				copyVar(ores).map(p => {
					const {
						productSequence,
						showSelfDeal,
						showSponsoredSupply,
						productName,
						///////////////////////
						productKey,
						productNum,
						productOptions,
					} = p
					let comm = {
						productSequence,
						showSelfDeal,
						showSponsoredSupply,
						productName,

						len: productOptions.length,
					}
					productOptions.map((o, j) => {
						numKey.map(k => (o[k] = numFormat(o[k])))
						//핫티수수료, 핫트수수료는 아래 calcFee에서 포맷 지정해준다

						if (this.isRental)
							o.rentalMonth = this.rentalRatioOpts.filter(ro => ro.value == o.rentalMonth)[0]

						res.push({
							...o,
							productKey,
							comm,
							isEditCharge: true,
							isEditSupply: true,
							saveStatus: o.saveStatus == 'Y',
							j,

							stockRes: '',
							isRental: this.isRental,
						})
					})
				})
				this.calcFee(res)
				this.res = res
			},
		},
	},
}
</script>

<style lang="scss" scoped>
.tb {
	.tbTooltip {
		position: absolute;
		top: calc(50% - 11px);
		right: 2px;
		padding: 0;
	}

	.form-control {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}

	.removeBtn,
	.addBtn {
		display: inline-block;
		width: 40%;
		margin: 0 5%;
		cursor: pointer;
		border: 1px solid var(--gray);
		border-radius: 5px;
	}
	.stockTable {
		border: 1px solid var(--gray);
		border-radius: 5px;
	}

	.stockBox {
		padding-right: 1.25rem !important;
		padding-left: 0 !important;
	}

	.allMoreBtn {
		width: 1.5rem;
		margin: -0.5rem 0 2rem 2.2rem;
		text-align: center;
		cursor: pointer;
		border: 1px solid var(--gray);
		border-radius: 5px;
	}
}
</style>
